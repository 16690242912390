import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  Notify,
  Toast
} from 'vant'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'home',
  component: () => import('../views/index.vue')
}, ]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  console.log(to, '路径---');
  var u = navigator.userAgent
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
  Notify.clear()
  Toast.loading({
    duration: 0,
    message: '加载中...',
    forbidClick: true,
  });
  // 每次进入页面的时候，刷新openid
  function getQueryString(name) {
    console.log('00', name);
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
    var r = window.location.search.substr(1).match(reg)
    if (r != null) return unescape(r[2]);
    return null
  }

  Toast.clear();
  next()
})
export default router